
  <app-accordion-item
    [title]="draftFinding.title"
    [itemId]="'draftFinding_' + draftFinding.id"
    (accordionClickRequest)="handleAccordionClickAction(draftFinding)"
    [open]="draftFinding.is_open">


    <div class="col-md-12">
      <div class="d-flex justify-content-end">
        @if(reportType == 'internal-department'){
          <a href="javascript:void(0)"
            (click)="openFindingConversationPanel(draftFinding)"
            class="a-link">Comments
          </a>
        }


        &nbsp;
        @if(reportType == 'internal-department' && !draftFinding.accepted_by && !draftFinding.challenged_by && hasModifiPermission){
        <div class="table-item-action-dropdown">
          <!--  START MORE BUTTON SECTION -->
          <button type="button"
              data-bs-toggle="dropdown"
              class="action-button" id="action1"
              aria-expanded="false">
            <img src="assets/images/icons/icon-more.svg" alt="More Actions">
          </button>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="action1">

            @if (draftFinding.id){
              <li>
                <a class="dropdown-item"
                (click)="openEditFindingForm(draftFinding)">
                  <img src="assets/images/icons/icon-edit.svg" alt="Edit Finding"> Edit Finding
                </a>
              </li>
              <li>
                <a class="dropdown-item" (click)="deleteFinding(draftFinding.id)">
                  <img src="assets/images/icons/icon-delete.svg" alt="Delete Finding"> Delete Finding
                </a>
              </li>
            }
          </ul>
          <!--  START MORE BUTTON SECTION -->
        </div>
        }
      </div>



    </div>

    <div class="row">
      <div class="col-md-12">
        @if(isDepartmentReview()){
          <app-draft-report-finding-status
          [draftFinding]="draftFinding"
          ></app-draft-report-finding-status>
        }
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="sm-widget-block align-items-start">
          <img
            src="assets/images/audit-icons/icon-auditable-item.svg"
          />
          <div class="ms-2">
            <label class="label-text">Auditable Item </label>
            <p class="p-text">
              {{ draftFinding.auidtable_item_title }}
            </p>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="sm-widget-block align-items-start">
          <img
            src="assets/images/audit-icons/icon-department.svg"
          />
          <div class="ms-2">
            <app-label-display
              [label]="'Department'"
              [type]="'text'"
              [value]="'Finance'">
            </app-label-display>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="sm-widget-block align-items-start">
          <img src="assets/images/audit-icons/icon-severity.svg" />
          <div class="ms-2">
            <app-label-display
              [label]="'Severity'"
              [type]="'status'"
              [status]="draftFinding.severity_level">
            </app-label-display>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      @if(draftFinding.description){
        <div class="col-md-12">
          <h4>Description</h4>
          <p class="p-text">
            <app-read-more
              [text]="draftFinding.description"
              [maxLength]="maxLength"
            ></app-read-more>
          </p>
        </div>
      }
      @if(draftFinding.recommendations){
        <div class="col-md-12 mt-3">
          <h4>Recommendations</h4>
          <p class="p-text">
            <app-read-more
              [text]="draftFinding.recommendations"
              [maxLength]="maxLength"
            ></app-read-more>
          </p>
        </div>
      }

      <div class="col-md-12 mt-3">
        @if(draftFinding.documents.length>0){
        <h4 class="mb-3">Evidence</h4>
        <div class="row">
          @for (document of draftFinding.documents; track document){
          <div class="col-md-6">
            <div
              class="uploaded-file justify-content-between align-items-center mb-2 mt-1"
            >
              <div class="d-flex">
                <img
                  src="assets/images/file-icons/icon-pdf.svg"
                  width="30"
                  class="me-3"
                />
                <div>
                  {{ document.original_name | truncate : 30 }}
                  <br /><small>{{
                    document.size | fileSize
                  }}</small>
                </div>
              </div>
              <a [href]="document.downloadUrl">
                <div class="delet-icon cursor-pointer">
                  <img
                    src="assets/images/icons/icon-download-2.svg"
                  />
                </div>
              </a>
            </div>
          </div>
          }
        </div>
        }

        <app-audit-details-report-details-conversations
          [draftReportStatus]="draftReportStatus"
          [reportType]="reportType"
          (openFindingExternalConversationFormRequest)="openFindingExternalConversationForm(draftFinding)"
          (openFindingAcceptFormRequest)="openFindingAcceptForm(draftFinding)"
          (openFindingChallengeFormRequest)="openFindingChallengeForm(draftFinding)"
          [draftFinding]="draftFinding">
        </app-audit-details-report-details-conversations>
      </div>
    </div>

    <app-audit-details-report-details-action-plan
      [draftFinding]="draftFinding"
      [reportType]="reportType"
      [draftReportStatus]="draftReportStatus"
      (openFindingActionPlanFormRequest)="openFindingActionPlanForm($event)"
      (deleteFindingActionPlanRequest)="deleteFindingActionPlan($event)"
    ></app-audit-details-report-details-action-plan>
  </app-accordion-item>
